<template>
  <Alerta />
  <div class="views">
    <component :is="layout" />
  </div>
</template>

<script setup>
import { computed, onMounted, watch, inject } from 'vue'
import { useRouter, useRoute } from 'vue-router'
import { useStoreAreas, useStoreAlerta, useStorePerfil } from '@stores'
import { helperToken, helperRotas } from '@helpers'
import Alerta from '@components/global/alerta/Alerta.vue'

const { currentRoute } = useRouter()

const route = useRoute()
const router = useRouter()
const storeAreas = useStoreAreas()
const storeAlerta = useStoreAlerta()
const storePerfil = useStorePerfil()
const emitter = inject('emitter')

/* ---- Carregar Dados ---- */

async function carregarPerfil() {
  await storePerfil.receberPorId()
  emitter.on('atualizarPerfil', carregarPerfil)
}

async function carregarArea(hotlink) {
  await storeAreas.receberPorHotlink(hotlink)
  document.title = storeAreas.area?.nome || 'Área de Membros'
}

/* ---- Funcoes Auxiliares ---- */

function verificarToken() {
  const rotaInternaOk = helperRotas.verificarRotaInterna()
  if (!rotaInternaOk) return

  const hotlink = route.params.hotlink
  if (!hotlink) return

  const tokenOk = helperToken.detectarTokenOk()
  if (tokenOk) return

  router.push(`/${hotlink}`)
}

function mostrarAlerta(payload) {
  const dados = {
    visivel: true,
    mensagem: payload.mensagem,
    classe: payload.tipo
  }
  storeAlerta.mostrarAlerta(dados)
}

/* ---- Metodos Para Remover ---- */

const layout = computed(() => {
  if (!currentRoute?.value?.name) return
  return currentRoute?.value.meta.layout || 'default-layout'
})

/* ---- Montagem ---- */

onMounted(() => {
  emitter.on('mostrarAlerta', (payload) => mostrarAlerta(payload))
  const hotlink = route.params.hotlink
  if (!hotlink) return
  carregarArea(hotlink)
})

/* ---- Watch ---- */

watch(
  () => route.path,
  () => {
    const rotaInternaOk = helperRotas.verificarRotaInterna()
    if (!rotaInternaOk) return
    verificarToken()
    carregarPerfil()
  },
  { immediate: true }
)

watch(
  () => route.params.hotlink,
  (newHotlink) => {
    if (!newHotlink) return
    carregarArea(newHotlink)
  },
  { immediate: true }
)
</script>
