/* ---- Imports ---- */

import { createRouter, createWebHistory } from 'vue-router'

/* ---- Routes ---- */

import app from './app'

/* ---- System ---- */

const router = createRouter({
  history: createWebHistory(),
  routes: [
    ...app
  ]
})

router.afterEach((to) => { document.title = to.meta.title })

export default router
