function ajustarAlturaTextarea(el, alturaMaxima) {
  el.style.height = 'auto'
  const deslocamento = el.offsetHeight - el.clientHeight
  const altura = Math.min(el.scrollHeight + deslocamento, alturaMaxima)
  el.style.height = `${altura}px`
}

export const autoRedimensionarTextArea = {
  mounted(el) {
    const alturaMaxima = parseInt(getComputedStyle(el).maxHeight, 10) || 1000
    ajustarAlturaTextarea(el, alturaMaxima)

    el.addEventListener('input', () => ajustarAlturaTextarea(el, alturaMaxima))
  },
  updated(el) {
    const alturaMaxima = parseInt(getComputedStyle(el).maxHeight, 10) || 1000
    ajustarAlturaTextarea(el, alturaMaxima)
  },
  unmounted(el) {
    el.removeEventListener('input', () => ajustarAlturaTextarea(el, alturaMaxima))
  }
}
